<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <div>
        <CoreContainer class="sim-category">
            <BaseContainerContent class="sim-category__content"
                                  :class="{ 'sim-category__content--unfilterable': productFiltersResponse?.getItem()?.isFilterPanelVisible() }"
                                  :padding="{ top: 'none', bottom: 'large' }"
            >

                <!--  CATEGORY INFO  -->
                <!--  includes the title, description and sub-categories  -->
                <CategoryInfo v-if="categoryResponse?.getItem()"
                              class="sim-category__info"
                              :category="categoryResponse!.getItem()!"
                              :sub-categories="subCategoriesResponse?.getItems()!"
                />


                <!--  FILTERS  -->
                <!--  a floating panel on the left side of the page with filters  -->
                <FilterPanel v-if="productFiltersResponse?.getItem()?.isFilterPanelVisible()"
                             :filters="productFiltersResponse?.getItem()?.getFilterBlocks() ?? []"
                             class="sim-category__filters"
                             :loading="areFiltersLoading"
                             @change="handleFiltersChange"
                />

                <div ref="productsListWrapperEl" class="sim-category__products">
                    <div v-if="productFiltersResponse?.getItem()?.getProducts().length" class="flex flex-wrap items-center justify-between gap-x-4 gap-y-8">
                        <UiPaginationInfo
                            v-if="productFiltersResponse?.getItem()?.paginationData"
                            t="filters.products_range"
                            :pagination="productFiltersResponse!.getItem()!.paginationData!"
                        />

                        <FilterSortSelect
                            v-if="productFiltersResponse?.getItem()?.sortOptions"
                            :options="productFiltersResponse!.getItem()!.sortOptions!"
                            @change="handleSortChange"
                        />
                    </div>

                    <hr class="mb-7 mt-4">

                    <FilterSelectedItems
                        v-if="areFiltersSelected"
                        :selected-filters="productFiltersResponse!.getItem()!.getSelectedFilters()!"
                        class="mb-8"
                        :loading="areFiltersLoading"
                        @change="handleFiltersChange"
                    />

                    <div v-if="productFiltersResponse?.getItem()?.getProducts().length" class="sim-category__product-list">
                        <ProductCard
                            v-for="product in productFiltersResponse?.getItem()?.getProducts()"
                            :key="product.id!"
                            class="!w-full"
                            :product="product"
                            :loading="areFiltersLoading || areProductsLoading"
                        />
                    </div>

                    <div v-if="productFiltersResponse?.getItem()?.getProducts().length" class="mt-14 flex flex-col items-center justify-center gap-6 sm:flex-row sm:justify-between">
                        <BaseUiPagination
                            :aria-label="$t('accessibility.pagination_for_products')"
                            :number-of-pages="productFiltersResponse?.getItem()?.paginationData?.pages"
                            :current-page="productFiltersResponse?.getItem()?.paginationData?.page"
                            class="mr-auto"
                            @change="handlePaginationChange"
                        />

                        <BaseUiButton
                            v-if="!productFiltersResponse?.getItem()?.paginationData?.hasReachedLastPage()"
                            color="primary"
                            size="large"
                            :on-click="handleShowMoreItems"
                        >
                            <template #leading>
                                <IconArrowRight rotate="right" class="text-simplo" />
                            </template>

                            <i18n-t keypath="filters.display_more" scope="global">
                                <template #number>
                                    {{ productFiltersResponse?.getItem()?.paginationData?.perPage }}
                                </template>
                            </i18n-t>
                        </BaseUiButton>
                    </div>

                    <NoResultsComponent v-else
                                        image-url="/images/category.svg"
                                        :title="areFiltersSelected ? $t('pages.category.filtered_empty') : $t('pages.category.empty')"
                                        :subtitle="areFiltersSelected ? $t('pages.category.filtered_empty_search') : $t('search.products')"
                                        class="mt-16"
                    />
                </div>

            </BaseContainerContent>
        </CoreContainer>

        <!--  NEWSLETTER SECTION  -->
        <SectionNewsletter />
    </div>
</template>

<script lang="ts" setup>
const { page, syncedPage } = useCurrentPage()

const route = useRoute()
const router = useRouter()
const filterSlug = ref<string>(route.fullPath)
// re-fetch products on navigation in history
watch(() => route.fullPath, (newVal) => {
    if (newVal === filterSlug.value) return
    handleFiltersChange(newVal)
})

const areFiltersSelected = computed(() => {
    return productFiltersResponse?.value?.getItem()?.getSelectedFilters().length
});

const [
    { data: categoryResponse },
    { data: subCategoriesResponse },
    {
        data: productFiltersResponse,
        refresh: refreshProductFilters,
    },
] = await Promise.all([
    // Category
    useCategoriesApiService()
        .onlyAttrs([
            CategoryModel.ATTR_NAME,
            CategoryModel.ATTR_DESCRIPTION,
        ])
        .embed([
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
            CategoryModel.EMBED_IMAGE_URL,
        ])
        .forId(page.value!.model!.id)
        .useGet(),
    // Sub-Categories
    useCategoriesApiService()
        .whereEquals(CategoryModel.ATTR_PARENT_ID, page.value!.model!.id)
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
            CategoryModel.ATTR_DESCRIPTION,
        ])
        .embed([
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
            CategoryModel.EMBED_IMAGE_URL,
            CategoryModel.EMBED_URLS,
        ])
        .useGet(),
    // Products
    useProductsFilterApiService()
        .embed([
            ProductModel.EMBED_COMPUTED_TAXED_PRICES,
            ProductModel.EMBED_DISCOUNT_PERCENTS,
            ProductModel.EMBED_DEFAULT_IMAGE_URL,
            ProductModel.EMBED_URLS,
            ProductModel.EMBED_PRODUCT_AVAILABILITY,
            ProductModel.EMBED_ACTIVE_PRODUCT_VARIATIONS_COUNT,
            ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES,
            ProductModel.EMBED_SECONDARY_IMAGE_URL,
        ])
        .usePost({ request_uri: filterSlug }, { watch: false }),
])

const productsListWrapperEl = ref<HTMLElement | null>(null)

const areFiltersLoading = ref<boolean>(false)
async function handleFiltersChange(url: string) {
    areFiltersLoading.value = true
    await _applyFilters(url)
    areFiltersLoading.value = false
}

async function handleSortChange(url: string) {
    areProductsLoading.value = true
    await _applyFilters(url)
    areProductsLoading.value = false
}

/**
 * Sets the new url to filter the products with.
 * Also updates the url in the browser.
 *
 * !! THIS METHOD IS NOT MEANT TO BE CALLED FROM THE UI !!
 *
 * @param url the new url to filter with
 */
async function _applyFilters(url: string) {
    filterSlug.value = url
    await refreshProductFilters()
    // redirect to the URI from BE if available (to hide `?page=1`, for example)
    await router.replace(productFiltersResponse.value?.getItem()?.requestUri ?? url)
}

const areProductsLoading = ref<boolean>(false)
async function handlePaginationChange(newPage: number) {
    const newUrl = productFiltersResponse.value?.getItem()?.paginationData?.getPageUrl(newPage)
    if (!newUrl) return
    areProductsLoading.value = true
    await _applyFilters(newUrl)
    areProductsLoading.value = false
    productsListWrapperEl.value?.scrollIntoView({ behavior: 'smooth' })
}


// FETCH MORE ITEMS

async function handleShowMoreItems() {
    const newUrl = productFiltersResponse.value?.getItem()?.paginationData?.getNextPageRangeUrl()
    if (!newUrl) return
    await _applyFilters(newUrl)
}

</script>

<style lang="scss" scoped>
@use "sass:math";

.sim-category__content {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include more-than(lg) {
        display: grid;
        grid-template-columns: 20rem 1fr;
        gap: 2.75rem 3rem;
        grid-template-areas:
            "filters info"
            "filters products"
            "filters - ";
    }

    @include more-than-custom(1920) {
        grid-template-columns: 22rem 1fr;
    }
}

.sim-category__content--no-filters {
    grid-template-columns: 1fr;
    column-gap: 0;
    grid-template-areas:
            "info"
            "products"
            "- ";
}

.sim-category__info {
    grid-area: info;
}

.sim-category__filters {
    grid-area: filters;
    margin-bottom: auto;
}

.sim-category__products {
    grid-area: products;
    scroll-margin: 10rem;
}

.sim-category__product-list {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

    @include more-than-custom(632) {
        grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    }
}

</style>
